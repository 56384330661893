// Normalize Styles
@import 'node_modules/normalize.css/normalize';

// Import Modules
@import '../_modules/link/link';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: 'Titillium Web', sans-serif;
  background-image: url("/images/brown-design-hardwood-REDUCED.jpg");
  background-size: 3000px;

}

.container {
  height: 100%;
  position: relative;
}

.visitcard {
  position: absolute;
  height: 400px; width: 700px;
  top: 0; left: 0; bottom: 0; right: 0;
  margin: auto;
  background-color: #fff;
  transform: rotate(3deg);
  -webkit-box-shadow: 2px 2px 4px 0px rgba(50,50,50,0.8);
  -moz-box-shadow:    2px 2px 4px 0px rgba(50,50,50,0.8);
  box-shadow:         2px 2px 4px 0px rgba(50,50,50,0.8);
  background: url('/images/handmadepaper.png') repeat;
  border-right: 1px solid #666;
  border-bottom: 1px solid #666;
  img {
    position: absolute;
    top: 10%;
    left: 6%;
    width: 125px;
    opacity: 0.77;
  }
  p {
    text-align: right
  }
}

.content {
  padding: 32% 7% 0 23%;
  opacity: 0.7;
}

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}

p {
  font-size: 24px;
  margin: 0;
  &#name {
    font-size: 34px;
    margin-bottom: 10px;
  }
}