// link styles
a {
    text-decoration: none;
}
// unvisited link
a:link,
a:visited ,
a:active {
    color: #000;
}

// mouse over link
a:hover {
    color: #777;
}
